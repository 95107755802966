import { FC, HTMLAttributes } from 'react'

import { cva } from 'class-variance-authority'

import { twMerge } from '~/lib/utils'

type ButtonVariant = 'default' | 'outlined'
type ButtonColor = 'brand' | 'cta'

type ButtonProps = {
  color: ButtonColor
  outlined?: boolean
} & HTMLAttributes<HTMLButtonElement>

const buttonVariants = cva(null, {
  variants: {
    variant: {
      default: 'text-white',
      outlined: 'border bg-white',
    },
    color: {
      brand: null,
      cta: null,
    },
  },
  compoundVariants: [
    {
      variant: 'default',
      color: 'brand',
      class: 'bg-brand hover:bg-brand-hover',
    },
    { variant: 'default', color: 'cta', class: 'bg-cta hover:bg-cta-hover' },
    {
      variant: 'outlined',
      color: 'brand',
      class: 'text-brand border-brand hover:bg-brand-bg',
    },
    {
      variant: 'outlined',
      color: 'cta',
      class: 'text-cta border-cta hover:bg-cta-bg',
    },
  ],
})

export const PrimaryButton: FC<ButtonProps> = ({
  color,
  outlined,
  children,
  className,
  ...props
}) => {
  const variant: ButtonVariant = outlined ? 'outlined' : 'default'

  return (
    <button
      className={twMerge(
        'h-[60px] w-full rounded px-4 py-2 text-body-bold',
        buttonVariants({ variant, color }),
        className,
      )}
      {...props}
    >
      {children}
    </button>
  )
}
